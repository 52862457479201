import './PureCloudLogin.css'
import React, { useEffect, Fragment } from 'react'
import config from '../../../config'
import constants from '../../../constants'

const PureCloudLogin = () => {
    useEffect(() => {
        console.log('PureCloudLogin component');
        console.log(`config is ${config}`)
        let sessionEnv = sessionStorage.getItem(constants.GC_ENVIRONMENT);
        console.log(`PureCloudLogin::: sessionEnv is ${sessionEnv}`);
        if(sessionEnv){
            //do nothing
            sessionStorage.setItem(constants.GC_ENVIRONMENT,sessionEnv);
        }else{
            sessionStorage.setItem(constants.GC_ENVIRONMENT,constants.GC_ENV_US_EAST_1);
        }
        const queryStringData = {
            response_type: 'token',
            client_id: config.clientID,
            redirect_uri: `${window.location.protocol}//${window.location.host}`
        }
        const param = Object.keys(queryStringData).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryStringData[k])}`).join('&')
        console.log(`Authorization request: ${param}`)
        window.location = `https://login.${sessionStorage.getItem(constants.GC_ENVIRONMENT)}/oauth/authorize?${param}`
    }, [])

    return (<Fragment></Fragment>)
}

export default PureCloudLogin