import utils from './utils'
import config from '../config'
import constants from '../constants'

function checkStatus(response) {
    if (!response.ok) {
        throw new Error(`HTTP ${response.status} - ${response.statusText}`);
    }
    return response;
}

// TODO: Clarify All URI resources with backend dev

export const getRecording = async (id) => {
    const uri = `${config.endpoints.backend}/recordings/${id}`
    // console.log('fetching uri:', uri)
    return fetch(uri).then(response => checkStatus(response) && response.arrayBuffer())
        .then(buffer => {
            // console.log('getRecording.buffer:', buffer)
            return { id, buffer }
        })
        .catch(error => {
            const err = { id, error }
            throw err
        })
}

export const getConversationsSettings = async (token) => {
    const uri = `${config.endpoints.backend}/settings/conversations`;
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token,
            env
        }
    })
}

export const updateConversationsSettings = async (token, conversationColumns) => {
    const uri = `${config.endpoints.backend}/settings/conversations`;
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'PUT',
        headers: {
            token,
            env
        },
        body: JSON.stringify({ conversationColumns })
    })
}

export const getConversations = async (token, searchCriteria) => {
    const uri = `${config.endpoints.backend}/search`
    console.log('fetching uri:', uri);
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'POST',
        headers: {
            token,
            env
        },
        body: JSON.stringify({
            searchCriteria
        })
    })
}

export const exportConversations = async (token, searchCriteria) => {
    let queryString = '?'
    if (utils.isIterable(searchCriteria)) {
        for (const [key, value] of searchCriteria) {
            if (key === 'selectedClients' || key === 'selectedChannels' || key === 'selectedQueues') continue;
            if (Array.isArray(value)) {
                for (const item of value)
                    if (item)
                        queryString += `${key}=${item}&`
            }
            else
                queryString += `${key}=${value}&`

        }
    }
    const uri = `${config.endpoints.backend}/export`;
    console.log('fetching uri:', uri);
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'POST',
        headers: {
            token,
            env
        },
        body: JSON.stringify({
            searchCriteria
        })
    })
}

export const getConversation = async (token, conversationId) => {
    const uri = `${config.endpoints.backend}/conversation?conversation_id=${conversationId}`;
    console.log('fetching uri:', uri);
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token,
            env
        }
    })
}


export const createUser = async (token, user) => {
    const uri = `${config.endpoints.backend}/users`;
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'POST',
        headers: {
            token,
            env
        },
        body: JSON.stringify({
            "userid": user.user.value,
            "email": user.user.label,
            "role": user.role,
            "disabled": user.disabled,
            "region": user.region,
            "divisions": user.divisions,
            "queues": user.queues
        })
    })
}

export const updateUser = async (token, id, user) => {
    const uri = `${config.endpoints.backend}/users/${id}`
    console.log(`updateUser input is ${JSON.stringify(user)}`)
    let newUser = {
        "userid": user.user[0].value,
        "email": user.user[0].label,
        "role": user.role,
        "disabled": user.disabled,
        "region": user.region,
        "divisions": user.divisions,
        "queues": user.queues,
        "department": user.department
    }
    console.log(`updateUser newUser is ${JSON.stringify(newUser)}`);
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'PUT',
        headers: {
            token,
            env
        },
        body: JSON.stringify(newUser)
    })
}

export const getUsersMe = async (token) => {
    const uri = `${config.endpoints.backend}/users/me`;
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token,
            env
        }
    })
}

export const getUserListAll = async (token) => {
    const uri = `${config.endpoints.backend}/users`;
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token,
            env
        }
    })
}

export const getUserByID = async (token, id) => {
    const uri = `${config.endpoints.backend}/users/${id}`;
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token,
            env
        }
    })
}


export const getUsersListSearch = async (token, searchCriteria) => {
    let queryString = '?'
    if (utils.isIterable(searchCriteria)) {
        for (const [key, value] of searchCriteria) {
            if (Array.isArray(value)) {
                for (const item of value)
                    if (item)
                        queryString += `${key}=${item}&`
            }
            else queryString += `${key}=${value}&`
        }
    }
    const uri = `${config.endpoints.backend}/users${queryString}`
    console.log('fetching uri:', uri)
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token,
            env
        }
    })
}
export const getAuditAccessReport = async (token, searchCriteria) => {
    let queryString = '?'
    if (utils.isIterable(searchCriteria)) {
        for (const [key, value] of searchCriteria) {
            if (Array.isArray(value)) {
                for (const item of value)
                    if (item) queryString += `${key}=${item}&`
            }
            else queryString += `${key}=${value}&`
        }
    }
    console.log(`getAuditAccessReport queryString is ${JSON.stringify(queryString)}`);
    const uri = `${config.endpoints.backend}/audit/access${queryString}`
    console.log('fetching uri:', uri)
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token,
            env
        }
    })
}

export const getAuditTermsReport = async (token, searchCriteria) => {
    let queryString = '?'
    if (utils.isIterable(searchCriteria)) {
        for (const [key, value] of searchCriteria) {
            if (Array.isArray(value)) {
                for (const item of value)
                    if (item) queryString += `${key}=${item}&`
            }
            else queryString += `${key}=${value}&`
        }
    }
    const uri = `${config.endpoints.backend}/audit/terms${queryString}`
    console.log('fetching uri:', uri)
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token,
            env
        }
    })
}

export const getDisclaimerContent = async (token) => {
    const uri = `${config.endpoints.backend}/settings/consent`;
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'GET',
        headers: {
            token,
            env
        }
    })
}
export const getConversationMetadata = async (token, conversationId, recordings) => {
    const uri = `${config.endpoints.backend}/conversations/${conversationId}/metadata`;
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'POST',
        headers: {
            token,
            env
        },
        body: JSON.stringify({ recordings })
    })
}


export const postUserLogin = async (token) => {
    const uri = `${config.endpoints.backend}/users/login`
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    console.log(`postUserLogin:: Calling ${uri} with GC environment as ${env}`);
    return utils.fetchWrapper(uri, {
        method: 'POST',
        headers: {
            'token':token,
            'env' :sessionStorage.getItem(constants.GC_ENVIRONMENT)
        }
    })
}

export const exportStatus = async (token, searchCriteria) => {
    let queryString = '?'
    if (utils.isIterable(searchCriteria)) {
        for (const [key, value] of searchCriteria) {
            if (Array.isArray(value)) {
                for (const item of value)
                    if (item) queryString += `${key}=${item}&`
            }
            else queryString += `${key}=${value}&`
        }
    }
    const uri = `${config.endpoints.backend}/export/status`;
    console.log('fetching uri:', uri);
    const env = sessionStorage.getItem(constants.GC_ENVIRONMENT);
    return utils.fetchWrapper(uri, {
        method: 'POST',
        headers: {
            token,
            env
        },
        body: JSON.stringify({ searchCriteria })
    })
}

export default {
    getUsersMe,
    getConversation,
    getConversations,
    getConversationsSettings,
    updateConversationsSettings,
    createUser,
    getUserListAll,
    getUserByID,
    updateUser,
    getUsersListSearch,
    getAuditAccessReport,
    getAuditTermsReport,
    getDisclaimerContent,
    postUserLogin,
    getConversationMetadata,
    exportConversations,
    exportStatus
}